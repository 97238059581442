import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Container } from "../../components/ProductDetails"
import { links } from "../../data/links"

const Title = styled.h1`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 4rem;
`

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 2rem;
  padding-bottom: 4rem;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
`

const CategoryBox = styled(Link)`
  background-color: white;
  width: 100%;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  transition: color 400ms;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    text-align: center;
    font-weight: 700;
  }
`

const ShopIndexPage = ({ data }) => {
  const { nodes } = data.navCategories

  // Create main nav links per vendor
  let mainNavLinks = []

  nodes.forEach(product => {
    product.metafields.forEach(field => {
      if (field.key === "navigation") {
        if (!mainNavLinks.includes(field.value)) {
          mainNavLinks = [...mainNavLinks, field.value]
        }
      }
    })
  })

  // Add ordering to navLinks
  let orderedLinks = []

  links.forEach(link => {
    if (mainNavLinks.includes(link.title)) {
      orderedLinks = [...orderedLinks, link.title]
    }
  })

  return (
    <Container>
      <Title>Bitte wähle eine Kategorie:</Title>
      <BoxGrid>
        {orderedLinks.map(link => (
          <CategoryBox to={`/shop/${link.toLowerCase()}`} key={link}>
            <p>{link}</p>
          </CategoryBox>
        ))}
      </BoxGrid>
    </Container>
  )
}

export const query = graphql`
  query NavCategoriesQuery($shopOwner: String!) {
    navCategories: allShopifyProduct(
      filter: {
        vendor: { eq: $shopOwner }
        metafields: { elemMatch: { key: { eq: "navigation" } } }
      }
    ) {
      nodes {
        id
        title
        productType
        handle
        metafields {
          key
          value
        }
        vendor
      }
    }
  }
`

export default ShopIndexPage
